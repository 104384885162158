<template>
    <div class="my-order">
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <div v-if="curIndex == 0" class="record-list">
            <div v-for="(item,index) in recordList" :key="index" class="record-item">
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.OrderNumber')}}</div>
                    <div class="value" :style="{ color: item.ai == 1 ? '#5466EF' : '' }">
                        {{item.ai == 1 ? `AI (${item.id})` : item.id}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Currency')}}</div>
                    <div class="value">{{item.product.name}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Quantity')}}</div>
                    <div class="value">{{parseFloat(item.money)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                    <div class="value">{{parseFloat(item.service_fee)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.OpeningPrice')}}</div>
                    <div class="value">{{parseFloat(item.oprice)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.CurrentPrice')}}</div>
                    <div class="value">{{curPrice(item.product.id)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Direction')}}</div>
                    <div class="value" :style="{ color: item.direction == 1 ? '#01BD8B' : '#FB474E' }">{{item.direction == 1 ? $t('SecondContract.BuyUp') : $t('SecondContract.BuyDown')}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.EstimatedProfitLoss')}}</div>
                    <div class="value profit">
                        <span v-if="item.direction == 1">{{curPrice(item.product.id) > parseFloat(item.oprice) ? (parseFloat(item.money) * parseFloat(item.profit_rate) / 100).toFixed(2) : `-${parseFloat(item.money)}`}}</span>
                        <span v-else>{{curPrice(item.product.id) < parseFloat(item.oprice) ? (parseFloat(item.money) * parseFloat(item.profit_rate) / 100).toFixed(2) : `-${parseFloat(item.money)}`}}</span>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Countdown')}}</div>
                    <div class="value">{{countdown(item.timer)}}</div>
                </div>
            </div>
        </div>
        <div v-if="curIndex == 1" class="record-list">
            <div v-for="(item,index) in recordList" :key="index" class="record-item">
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.OrderNumber')}}</div>
                    <div class="value" :style="{ color: item.ai == 1 ? '#5466EF' : '' }">
                        {{item.ai == 1 ? `AI (${item.id})` : item.id}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Currency')}}</div>
                    <div class="value">{{item.product.name}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Quantity')}}</div>
                    <div class="value">{{parseFloat(item.money)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                    <div class="value">{{parseFloat(item.service_fee)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.minute')}}</div>
                    <div class="value">{{item.minutes}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.OpeningPrice')}}</div>
                    <div class="value">{{parseFloat(item.oprice)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.ClosingPrice')}}</div>
                    <div class="value">{{parseFloat(item.price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Direction')}}</div>
                    <div class="value" :style="{ color: item.direction == 1 ? '#01BD8B' : '#FB474E' }">{{item.direction == 1 ? $t('SecondContract.BuyUp') : $t('SecondContract.BuyDown')}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.ProfitAndLoss')}}</div>
                    <div class="value profit" :style="{ color: parseFloat(item.profit) > 0 ? '#01BD8B' : '#FB474E' }">{{parseFloat(item.profit)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Time')}}</div>
                    <div class="value">{{item.created_at}}</div>
                </div>
            </div>
        </div>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination small @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="5"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next"
                :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabList: [this.$t('SecondContract.InTransaction'), this.$t('SecondContract.Closed')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                timer: null
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updateSecondOrder')
            this.$bus.$on('updateSecondOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        destroyed() {
            clearInterval(this.timer)
        },
        computed: {
            productList() {
                return this.$store.state.productList
            },
            
            curPrice() {
                return id => {
                    let price = 0
                    this.productList.forEach(item => {
                        if(id == item.id) {
                            price = parseFloat(item.price.close)
                        }
                    })
                    
                    return price
                }
            },
            
            countdown() {
                return s => {
                    return this.calCountdown(s)
                }
            }
        },
        methods: {
            calCountdown(second) {
                //得到小时 格式化成前缀加零的样式
                let h = parseInt(second / 60 / 60)
                h = h < 10 ? '0' + h : h
                //得到分钟 格式化成前缀加零的样式
                let m = parseInt(second / 60 % 60)
                m = m < 10 ? '0' + m : m
                //得到秒 格式化成前缀加零的样式
                let s = parseInt(second % 60)
                s = s < 10 ? '0' + s : s
            
                return `${h}:${m}:${s}`
            },
            
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                clearInterval(this.timer)
                this.$api.secondOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                    if(this.curIndex == 0) {
                        this.timer = setInterval(() => {
                            if(this.recordList.length > 0) {
                                this.recordList.map((item,index) => {
                                    if(item.timer <= 0) {
                                        this.recordList.splice(index,1)
                                    } else {
                                        item.timer--
                                    }
                                })
                            } else {
                                clearInterval(this.timer)
                            }
                        },1000)
                    } else {
                        clearInterval(this.timer)
                    }
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        margin-top: 4px;
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 3.5rem;
                line-height: 3.5rem;
                box-sizing: border-box;
                padding: 0 1.2rem;
                font-size: 1.3rem;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        .record-list {
            width: 100%;
            
            .record-item {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                border-top: 1px solid #EBEEF5;
                background-color: #FFFFFF;
                
                .info-item {
                    width: 48%;
                    margin-left: 4%;
                    
                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }
                    
                    &:nth-child(n+3) {
                        margin-top: 1.6rem;
                    }
                    
                    .label {
                        width: 100%;
                        margin-bottom: .8rem;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        width: 100%;
                        font-size: 1.2rem;
                        color: #000000;
                        word-break: break-all;
                    }
                    
                    .profit {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                }
            }
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>